export const actionTypes = {
  GET_PROJECT_MAINTENANCE_SUCCESS: 'GET_PROJECT_MAINTENANCE_SUCCESS',
  GET_PROJECT_MAINTENANCE_FIALURE: 'GET_PROJECT_MAINTENANCE_FIALURE',
  GET_PROJECT_MAINTENANCE__LOADER: 'GET_PROJECT_MAINTENANCE__LOADER',

  SEARCH_PROJECT_SUCCESS: 'SEARCH_PROJECT_SUCCESS',
  SEARCH_PROJECT_FAILURE: 'SEARCH_PROJECT_FAILURE',
  SEARCH_PROJECT_LOADER: 'SEARCH_PROJECT_LOADER',

  OPEN_PROJECT_SUCCESS: 'OPEN_PROJECT_SUCCESS',
  OPEN_PROJECT_FAILURE: 'OPEN_PROJECT_FAILURE',
  OPEN_PROJECT_LOADER: 'OPEN_PROJECT_LOADER',

  SET_DATA_PROJECT_MAINTENANCE: 'SET_DATA_PROJECT_MAINTENANCE',

  GET_fORM_BASICDATA_SUCCESS: 'GET_fORM_BASICDATA_SUCCESS',
  GET_fORM_BASICDATA_FAILURE: 'GET_fORM_BASICDATA_FAILURE',
  GET_fORM_BASICDATA_LOADER: 'GET_fORM_BASICDATA_LOADER',

  EDIT_PROJECT_SUCCESS: 'EDIT_PROJECT_SUCCESS',
  EDIT_PROJECT_FAILURE: 'EDIT_PROJECT_FAILURE',
  EDIT_PROJECT_LOADER: 'EDIT_PROJECT_LOADER',

  SAVE_SUCCESS: 'SAVE_SUCCESS',
  SAVE_FAILURE: 'SAVE_FAILURE',
  SAVE_LOADER: 'SAVE_LOADER',

  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAILURE: 'SUBMIT_FAILURE',
  SUBMIT_LOADER: 'SUBMIT_LOADER',

  MANAGE_PROJECT_SUCCESS: 'MANAGE_PROJECT_SUCCESS',
  MANAGE_PROJECT_FAILURE: 'MANAGE_PROJECT_FAILURE',
  MANAGE_PROJECT_LOADER: 'MANAGE_PROJECT_LOADER',

  CLONE_PROJECT_SUCCESS: 'CLONE_PROJECT_SUCCESS',
  CLONE_PROJECT_FAILURE: 'CLONE_PROJECT_FAILURE',
  CLONE_PROJECT_LOADER: 'CLONE_PROJECT_LOADER',

  CLOSE_PROJECT_SUCCESS: 'CLOSE_PROJECT_SUCCESS',
  CLOSE_PROJECT_FAILURE: 'CLOSE_PROJECT_FAILURE',
  CLOSE_PROJECT_LOADER: 'CLOSE_PROJECT_LOADER',

  SET_DATA_CASE_AUTHENTICATION: 'SET_DATA_CASE_AUTHENTICATION',

  RESOURCE_NEED_CASE_SUCCESS: 'RESOURCE_NEED_CASE_SUCCESS',
  RESOURCE_NEED_CASE_FAILURE: 'RESOURCE_NEED_CASE_FAILURE',
  RESOURCE_NEED_CASE_LOADER: 'RESOURCE_NEED_CASE_LOADER',

  SEARCH_EMP_PROJECT_SUCCESS: 'SEARCH_EMP_PROJECT_SUCCESS',
  SEARCH_EMP_PROJECT_FAILURE: 'SEARCH_EMP_PROJECT_FAILURE',
  SEARCH_EMP_PROJECT_LOADER: 'SEARCH_EMP_PROJECT_LOADER',

  SUBMIT_SWAP_SUCCESS: 'SUBMIT_SWAP_SUCCESS',
  SUBMIT_SWAP_FAILURE: 'SUBMIT_SWAP_FAILURE',
  SUBMIT_SWAP_LOADER: 'SUBMIT_SWAP_LOADER',

  RESET_SWAP_DATA: 'RESET_SWAP_DATA',

  SWAP_CASE_DETAILS_SUCCESS: 'SWAP_CASE_DETAILS_SUCCESS',
  SWAP_CASE_DETAILS_FAILURE: 'SWAP_CASE_DETAILS_FAILURE',
  SWAP_CASE_DETAILS_LOADER: 'SWAP_CASE_DETAILS_LOADER',

  CLICK_APPLY_SUCCESS: 'CLICK_APPLY_SUCCESS',
  CLICK_APPLY_FAILURE: 'CLICK_APPLY_FAILURE',
  CLICK_APPLY_LOADER: 'CLICK_APPLY_LOADER',

  SUBMIT_PROJECT_UPDATE_SUCCESS: 'SUBMIT_PROJECT_UPDATE_SUCCESS',
  SUBMIT_PROJECT_UPDATE_FAILURE: 'SUBMIT_PROJECT_UPDATE_FAILURE',
  SUBMIT_PROJECT_UPDATE_LOADER: 'SUBMIT_PROJECT_UPDATE_LOADER',
};
